/* Makes in-page-navigation sticky on scroll */
let navbar = document.querySelector('.stockists-grid__header');

if (navbar) { 
    let navPos = navbar.getBoundingClientRect().top;

    window.addEventListener("scroll", e => {
    let scrollPos = window.scrollY;
        if (scrollPos > navPos) {
            navbar.classList.add('stockists-grid__header--sticky');
        } else {
            navbar.classList.remove('stockists-grid__header--sticky');
        }
    });
}