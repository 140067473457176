import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function () {
    const swiperOptions = {
        modules: [Navigation,Pagination],
        slidesPerView: 1,
        loop: false,

        pagination: {
            el: '.content-carousel__pagination',
        },

        navigation: {
            nextEl: '.content-carousel__btn-next',
            prevEl: '.content-carousel__btn-prev',
        },
    };

    const postGridCarouselSwiper = new Swiper('.content-carousel-swiper-container', swiperOptions);
});