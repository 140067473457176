import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function () {
    const customerStoriesCarouselOptions = {
        modules: [Pagination],
        slidesPerView: 1,
        loop: false,
        autoHeight: true,

        pagination: {
            el: '.customer-stories-carousel__pagination',
            clickable: true,
        },
    };

    const customerStoriesCarouselSwiper = new Swiper('.customer-stories-carousel-swiper', customerStoriesCarouselOptions);
});