import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function () {
    const customerStoriesCarouselOptions = {
        modules: [Pagination],
        spaceBetween: 40,
        slidesPerView: 1.5,
        centeredSlides: true,
        loop: true,
        autoHeight: true,

        pagination: {
            el: '.shopper-reviews-carousel__pagination',
            clickable: true,
        },

        on: {
            init: setEqualHeight,
            resize: setEqualHeight,
        }, 

        breakpoints: {
            1440: {
                slidesPerView: 2.5,
            },
        }
    };

    const customerStoriesCarouselSwiper = new Swiper('.shopper-reviews-carousel-swiper', customerStoriesCarouselOptions);
});


// Function to set equal height for all slides based on the tallest slide
function setEqualHeight() {
    let maxHeight = 0;
    const slides = document.querySelectorAll('.shopper-reviews-carousel__slide');

    slides.forEach(slide => {
        const slideHeight = slide.offsetHeight;
        if (slideHeight > maxHeight) {
            maxHeight = slideHeight;
        }
    });

    slides.forEach(slide => {
        slide.style.height = `${maxHeight}px`;
    });
}