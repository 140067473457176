import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function () {
    const activitiesCarouselOptions = {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        loop: false,
        autoHeight: true,
        spaceBetween: 15,

        pagination: {
            el: '.activities-carousel__pagination',
            clickable: true,
        },

        navigation: {
            nextEl: '.activities-carousel__btn-next',
            prevEl: '.activities-carousel__btn-prev',
        },

        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 80,
            },
        }
    };

    const activitiesCarouselSwiper = new Swiper('.activities-carousel-swiper', activitiesCarouselOptions);
});