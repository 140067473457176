/* Makes in-page-navigation sticky on scroll */
let inPageNav = document.querySelector('.in-page-navigation');

if (inPageNav) { 
    let navPos = inPageNav.getBoundingClientRect().top;

    window.addEventListener("scroll", e => {
    let scrollPos = window.scrollY;
        if (scrollPos > navPos) {
            inPageNav.classList.add('in-page-navigation--sticky');
        } else {
            inPageNav.classList.remove('in-page-navigation--sticky');
        }
    });
}